<template>
    <div class="random-paper" v-loading="loading" element-loading-text="加载中..." element-loading-spinner="el-icon-loading">
        <HeadPath currentPath="随机组卷"></HeadPath>
        <PaperCreateSucceed v-if="isCreateSucceed"></PaperCreateSucceed>
        <div
            class="form"
            v-else-if="!createPaperParams.paper_id || (createPaperParams.paper_id && createPaperParams.category_id.length > 0)"
        >
            <el-form :model="createPaperParams" :rules="rules" ref="ruleForm" label-width="100px" class="form-box">
                <el-form-item label="试卷名称" prop="paper_name">
                    <el-input
                        type="text"
                        v-model="createPaperParams.paper_name"
                        placeholder="请输入试卷名称"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="题库分类" prop="category_id">
                    <el-cascader
                        v-model="createPaperParams.category_id"
                        :options="questionLibraryList"
                        placeholder="专业大类>专业中类"
                    ></el-cascader>
                </el-form-item>
                <el-form-item label="抽题方式">
                    <el-radio v-model="createPaperParams.extract_questions_type" :label="1">固定题目模式</el-radio>
                    <el-radio v-model="createPaperParams.extract_questions_type" :label="2">随机题目模式</el-radio>
                </el-form-item>
                <el-form-item label="题型信息" prop="rand_paper_data" class="type-form-item">
                    <div class="set-type-question">
                        <div class="hint">(至少选择一种题型)</div>
                        <div class="type-input-list">
                            <div class="type-input-item" v-for="(item, index) in questionTypeList" :key="index">
                                <span class="type-name"> {{ item }} </span>
                                <el-input
                                    v-model="createPaperParams.rand_paper_data[index].num"
                                    type="number"
                                    placeholder="输入试题数量"
                                ></el-input>
                                <span class="decorate">题</span>
                                <el-input
                                    v-model="createPaperParams.rand_paper_data[index].score"
                                    placeholder="输入每小题分数"
                                    type="number"
                                ></el-input>
                                <span class="decorate">分</span>
                            </div>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item>
                    <div class="total-score">
                        <span class="decorate">总分</span>
                        <el-input :placeholder="totalScore" disabled></el-input>
                        <span class="decorate">分</span>
                    </div>
                </el-form-item>
            </el-form>
            <div class="footer-button">
                <el-button @click="back">返回</el-button>
                <el-button class="blue-button" type="primary" @click="submitForm('ruleForm')">提交</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import HeadPath from "components/admin/paperManage/common/HeadPath";
import PaperCreateSucceed from "components/admin/paperManage/common/PaperCreateSucceed";

import { theoryLibSelect, theoryRandomPaperCreate, theoryGetRandomPaperDetail } from "@/utils/apis";
import { setTimeout } from "timers";

export default {
    name: "RandomPaper",
    data() {
        let validateScore = (rule, value, callback) => {
            let counts = value.filter((item) => item.num);
            let scores = value.filter((item) => item.score);
            this.callback = callback;
            if (counts.length <= 0) {
                callback(new Error("至少选择一种题型"));
            } else if (scores.length <= 0) {
                callback(new Error("必须设置分数"));
            } else if (scores.reduce((sum, item) => sum + item.score * item.num, 0) != 100) {
                callback(new Error("总分必须为100分"));
            } else {
                callback();
            }
        };
        return {
            loading: false,
            callback: () => {},
            isCreateSucceed: false,
            questionLibraryList: [],
            questionTypeList: ["单选", "多选", "判断", "填空", "问答"],
            createPaperParams: {
                paper_name: "",
                category_id: [],
                extract_questions_type: 1, //抽题方式  1固定  2随机
                rand_paper_data: [{}, {}, {}, {}, {}],
                paper_id: "",
            },
            rules: {
                paper_name: [
                    { required: true, message: "请输入试卷名称", trigger: "blur" },
                    { min: 3, max: 25, message: "长度必须在 3 到 25 个字符之间", trigger: "blur" },
                ],
                category_id: [{ required: true, message: "请选择题库分类", trigger: "blur" }],
                rand_paper_data: [{ validator: validateScore, trigger: "blur" }],
            },
        };
    },
    components: {
        HeadPath,
        PaperCreateSucceed,
    },
    mounted() {
        this.getQuestionLibrary();
        let id = this.$route.query.id;
        if (id) {
            this.createPaperParams.paper_id = id;
            this.editPaper();
        }
    },
    computed: {
        packParams() {
            let { createPaperParams } = this;
            let paperData = createPaperParams.rand_paper_data.map((item, index) => ({
                type: index + 1,
                num: item.num ? item.num : 0,
                score: item.score ? item.score : 0,
            }));
            return {
                ...createPaperParams,
                category_id: createPaperParams.category_id[1],
                rand_paper_data: JSON.stringify(paperData),
            };
        },
        totalScore() {
            let { createPaperParams } = this;
            let list = createPaperParams.rand_paper_data.filter((item) => item.score);
            return list.length <= 0
                ? 0
                : list.reduce((sum, item) => {
                      let num = !item.num ? 1 : item.num;
                      return sum * 1 + num * item.score;
                  }, 0);
        },
    },
    methods: {
        /**获取题库列表 */
        async getQuestionLibrary() {
            let res = await theoryLibSelect();
            if (res.code == 200) {
                this.questionLibraryList = res.data.map((item, index) => {
                    let packData = (obj) => {
                        return {
                            label: obj.name,
                            value: obj.id,
                        };
                    };
                    let temp = packData(item);
                    if (item.children.length > 0) {
                        temp.children = item.children.map(packData);
                    }
                    return temp;
                });
            } else {
                this.$message.warning(res.msg);
            }
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.createRandomPaper();
                } else {
                    return false;
                }
            });
        },
        async createRandomPaper() {
            let res = await theoryRandomPaperCreate(this.packParams);
            if (res.code == 200) {
                this.isCreateSucceed = true;
                this.$message.success(res.msg);
            } else {
                this.$message.warning(res.msg);
            }
        },
        async editPaper() {
            this.loading = true;
            let { createPaperParams } = this;
            let keys = ["single", "multiple", "judge", "blank", "answer"];
            let res = await theoryGetRandomPaperDetail({ paper_id: createPaperParams.paper_id });
            if (res.code == 200) {
                createPaperParams.paper_name = res.data.paper_name;
                createPaperParams.extract_questions_type = res.data.extract_questions_type
                this.$set(createPaperParams.category_id, 0, res.data.category_id_arr[0]);
                this.$set(createPaperParams.category_id, 1, res.data.category_id_arr[1]);
                keys.forEach((item, index) => {
                    let num = res.data[`${item}_num`] * 1;
                    let score = res.data[`${item}_score`] * 1;
                    if (num > 0) {
                        this.$set(createPaperParams.rand_paper_data[index], "num", num);
                    }
                    if (score > 0) {
                        this.$set(createPaperParams.rand_paper_data[index], "score", score);
                    }
                });
                this.loading = false;
            } else {
                this.$message.error(res.msg);
            }
        },
        back() {
            this.$router.back();
        },
    },
};
</script>

<style scoped lang="scss">
.random-paper {
    display: flex;
    flex-direction: column;
    height: 96%;
    padding: 30px;
    color: #13131b;
    .form {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    .form-box {
        flex: 1;
        width: 520px;
        span {
            margin: 0 10px;
            white-space: nowrap;
        }
        .set-type-question {
            .hint {
                color: #999;
            }
            ::v-deep .el-input {
                width: 180px;
            }
            .type-input-list {
                width: 555px;
                transform: translateX(-9%);
                .type-input-item:nth-child(1) {
                    margin-top: 10px;
                }
                .type-input-item {
                    display: flex;
                    margin-bottom: 20px;
                }
            }
        }
        .total-score {
            transform: translateX(39%);
            ::v-deep .el-input {
                width: 180px;
            }
        }
        .type-form-item {
            margin-bottom: 0;
        }
    }
    .footer-button {
        display: flex;
        justify-content: center;
        margin-bottom: 28px;
        ::v-deep .el-button {
            width: 80px;
            height: 40px;
        }
        ::v-deep .blue-button {
            background-color: #1122d8;
            border-color: #1122d8;
        }
    }
}
//去除number input的指示器
/* chrome */
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
/* 火狐浏览器 */
::v-deep input[type="number"] {
    -moz-appearance: textfield;
}
</style>
